<template>
  <a-tabs v-model:activeKey="activeKey">
    
    <a-tab-pane v-for="item in moduleList" :key="item.id" :tab="item.name">
      <!-- 语音线路 -->
      <div class="privacy-box" v-if="activeKey == '1'">
        <div class="flex1">
          <div>

          </div>
          <div>
              <p class="testShow">若线路存在录音费，则自动在设置资费基础上+录音费用</p>
          </div>
          <div>
            <el-button size="small" @click="viewNotes">查看备注</el-button>
          </div>
        </div>
       
        <div class="flex">
          <div style="margin-left: 10px">接口类型：</div>
          <el-select
            v-model="interfaceIdType"
            placeholder="请选择查询的接口类型"
            style="width: 20%"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in interface_id"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <!-- <div style="margin-left: 10px">计费类型：</div>
          <el-select
            v-model="searchForm.type"
            clearable
            placeholder="请选择"
            style="width: 20%"
          >
            <el-option
              v-for="item in billingType"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select> -->
          <div style="margin-left: 10px">服务商：</div>
          <el-select
            placeholder="请选择查询的服务商"
            v-model="searchForm.amountId"
            size="small"
            style="width: 20%"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in amount_id"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>

        <div class="add-box">
          <el-button
            size="small"
            plain
            type="success"
            round
            @click="queryprivacy"
          >
            查询
            <!-- <icon-font type="icon-web-icon-" class="img-icon"/> -->
          </el-button>
          <el-button
            size="small"
            plain
            type="success"
            round
            @click="addprivacy"
          >
            增加
            <!-- <icon-font type="icon-web-icon-" class="img-icon"/> -->
          </el-button>
        </div>
        <div class="privacy-most">
          <div class="pItem-box" v-for="(pItem, p) in privacylist" :key="p">
            <el-select
              v-model="pItem.interfaceId"
              placeholder="请选择"
              @change="changeSel(pItem)"
              filterable
              :disabled="
                pItem.interfaceId == null || pItem.interfaceId == ''
                  ? false
                  : true
              "
            >
              <el-option
                v-for="item in interfaceList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <div class="flex">
              <div>月租：</div>
              <div class="input-box"  :class="{ 'input-error': !validateItem(pItem) }">
                <el-input
                  class="input-price-width"
                  v-model="pItem.monthlyRent"
                  @input='showPrice(pItem)'
                 :placeholder="pItem.monthlyRent1 !== -1 &&pItem.monthlyRent1 ? `成本价格 ${pItem.monthlyRent1}` : '请输入价格'"
                  ><template #append>￥</template></el-input
                >
              </div>
            </div>

            <div class="flex">
              <div>单计费：</div>
              <div class="input-box"  :class="{ 'input-error': !validateItem1(pItem) }">
                <el-input
                 @input='showPrice(pItem)'
                  class="input-price-width"
                  v-model="pItem.singleBilling"
                 :placeholder="pItem.singleBilling1 !== -1 &&pItem.singleBilling1 ? `成本 ${pItem.singleBilling1}`: '请输入价格'"
                  ><template #append>￥</template></el-input
                >
              </div>
            </div>

            <div class="flex">
              <div>双计费：</div>
              <div class="input-box"  :class="{ 'input-error': !validateItem2(pItem) }">
                <el-input
                 @input='showPrice(pItem)'
                  class="input-price-width"
                  v-model="pItem.dualBilling"
                  :placeholder="pItem.dualBilling1 !== -1 &&pItem.dualBilling1 ?`成本 ${pItem.dualBilling1}`: '请输入价格'"
                  ><template #append>￥</template></el-input
                >
              </div>
            </div>
            <div class="flex" style="padding-left:30px">
                    <el-button size="small" @click="addNotes(pItem)">添加备注</el-button>
            </div>

            <div class="out-left">
              <el-tooltip content="开启/禁用" placement="top" effect="light">
                <el-switch
                  style="display: block"
                  v-model="pItem.status"
                  :active-value="0"
                  :inactive-value="1"
                  active-color="#637DFF"
                  inactive-color="#ff6e3e"
                >
                </el-switch>
              </el-tooltip>
            </div>
            <div class="out-left">
              <el-button
                size="small"
                style="color: red"
                type="text"
                round
                @click="deleteOne(p, pItem)"
              >
                删除
                <!-- <icon-font type="icon-web-icon-" class="img-icon"/> -->
              </el-button>
            </div>
          </div>
        </div>

        <div class="bottom-box1">
          <!-- <el-button size="small" round @click="cancel">
                        取消
                    </el-button> -->
          <pagi-nation
            :pageSizesType="[10, 20, 50]"
            :style="{ marginTop: '20px' }"
            :total="groupTotal"
            :limit="groupPageSize"
            :page="groupPage"
            @handleChange="handleGroupChange"
          ></pagi-nation>
          <el-button size="small" type="primary" plain round @click="save">
            保存
            <!-- <icon-font type="icon-web-icon-" class="img-icon"/> -->
          </el-button>
        </div>
      </div>
         <!-- 设置弹窗 -->
  
      <!-- 语音质检 -->
      <div v-else>
        <div class="privacy-most">
          <div style="margin-bottom: 10px" v-if="activeKey == '3'">
            <el-radio-group v-model="telTypeRadio">
              <el-radio label="0">多方</el-radio>
         <!-- <el-radio label="1">棱睿1</el-radio>
              <el-radio label="2">电话邦</el-radio>
              <el-radio label="3">棱睿2</el-radio>
              <el-radio label="5">浙江风临</el-radio>
              <el-radio label="6">飞亚</el-radio>
              <el-radio label="7">林素</el-radio> -->
            </el-radio-group>
          </div>

          <div class="pItem-box">
            <div class="input-box-other">
              <el-tooltip content="用户使用价格" placement="top" effect="light">
                <el-input
                  v-model="otherList.usePrice"
                  placeholder="请输入用户使用价格"
                  ><template #append>￥</template></el-input
                >
              </el-tooltip>
            </div>
            <div class="input-box-other">
              <el-tooltip content="接口能力价格" placement="top" effect="light">
                <el-input
                  v-model="otherList.apiPrice"
                  placeholder="请输入接口能力价格"
                  ><template #append>￥</template></el-input
                >
              </el-tooltip>
            </div>
            <div v-if="activeKey == '2'" class="input-box-other">
              <el-tooltip
                content="一句话识别价格"
                placement="top"
                effect="light"
              >
                <el-input
                  v-model="otherList.asrPrice"
                  placeholder="一句话识别价格"
                  ><template #append>￥</template></el-input
                >
              </el-tooltip>
            </div>
            <div v-if="activeKey == '2'" class="out-left">
              <el-tooltip
                content="一句话识别价格状态"
                placement="top"
                effect="light"
              >
                <el-switch
                  style="display: block"
                  v-model="otherList.asrStatus"
                  :active-value="0"
                  :inactive-value="1"
                  active-color="#637DFF"
                  inactive-color="#ff6e3e"
                >
                </el-switch>
              </el-tooltip>
            </div>
            <div class="out-left">
              <el-tooltip content="开通状态" placement="top" effect="light">
                <el-switch
                  style="display: block"
                  v-model="otherList.useStatus"
                  :active-value="0"
                  :inactive-value="1"
                  active-color="#637DFF"
                  inactive-color="#ff6e3e"
                >
                </el-switch>
              </el-tooltip>
            </div>
            <div class="out-left">
              <el-tooltip content="能力状态" placement="top" effect="light">
                <el-switch
                  style="display: block"
                  v-model="otherList.apiStatus"
                  :active-value="0"
                  :inactive-value="1"
                  active-color="#637DFF"
                  inactive-color="#ff6e3e"
                >
                </el-switch>
              </el-tooltip>
            </div>
          </div>
        </div>

        <div class="bottom-box">
          <!-- <el-button size="small" round @click="cancelOther">
                        取消
                    </el-button> -->
          <el-button size="small" round @click="saveOther">
            保存
            <!-- <icon-font type="icon-web-icon-" class="img-icon"/> -->
          </el-button>
        </div>
      </div>
    </a-tab-pane>
  </a-tabs>
    <el-dialog title="添加备注" v-model="dialogVirtualVisible" :show-close="true" :close-on-click-modal="false"
      :destroy-on-close="true" width="30%">
      <div>
         <el-input
  type="textarea"
  :autosize="{ minRows: 4, maxRows: 8}"
  placeholder="请输入内容"
  v-model="textarea1">
</el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button  size="small"  plain round @click="dialogVirtualVisible = false" class="cancel" >取消</el-button>
          <el-button size="small"  plain round @click="handleVirtualAdd" class="confirmAdd" :loading="loading">确认</el-button>
        </span>
      </template>
    </el-dialog>
     <el-dialog title="查看备注" v-model="dialogVirtualVisible1" :show-close="true" :close-on-click-modal="false"
      :destroy-on-close="true" width="40%">
            <el-table v-loading="loading" ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
            max-height="500px" size="mini" :row-style="{height:'50px'}" :cell-style="{padding:'0px'}"
            @selection-change="handleSelectionChange">
         
         
            <el-table-column  fixed label="线路名称">
              <template #default="scope">{{ scope.row.interfaceName == null?'-': scope.row.interfaceName}}</template>
            </el-table-column>
            <el-table-column  fixed label="内容">
              <template #default="scope">{{ scope.row.remark == null?'-': scope.row.remark}}</template>
            </el-table-column>
            <el-table-column fixed label="操作人">
              <template #default="scope">{{ scope.row.sysNickName == null?'-': scope.row.sysNickName}}</template>
            </el-table-column>
            
          
            <el-table-column  label="添加时间">
              <template #default="scope">{{ scope.row.createTime == null?'-': scope.row.createTime}}</template>
            </el-table-column>
          </el-table>
             <div class="pager">
      <div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          background :page-sizes="[10, 50, 100, 200]" :page-size="groupPageSize1"
          layout="total, sizes, prev, pager, next, jumper" :total="groupTotal1">
        </el-pagination>
      </div>
    </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button  size="small"  plain round @click="dialogVirtualVisible1 = false" class="cancel" >确定</el-button>
          
        </span>
      </template>
    </el-dialog>
</template>

<script>
import app from "@/api/web/app";
import webUser from "@/api/web/user";
import userModule from "@/api/web/userModule";
import userPrice from "@/api/open/userPrice/userPrice";
import interfaces from "@/api/open/interface/interface";
import blackUserPrice from "@/api/open/blackUserPrice/blackUserPrice";
import voiceUserPrices from "@/api/open/voiceUserPrice/voiceUserPrices";
import { message } from "ant-design-vue";
import Pagination from "./Pagination.vue";
import interfacets from "@/api/open/privacy/interface";
import amountts from "@/api/open/privacy/amount";

export default {
  components: {
    "pagi-nation": Pagination,
  },
  props: {},
  data() {
    return {
      loading:false,
      dialogVirtualVisible:false,
      dialogVirtualVisible1:false,
      telTypeRadio: null,
      amount_id: [], // 拿到的通信账户类型
      searchForm: {
        amountId: "",
        type: "",
      },
      interfaceIdType: "", //接口类型
textarea1:'', //备注
userData:{},//添加备注传递参数
tableData:[], //查看备注
      interface_id: [], // 拿到的接口类型
      groupTotal: 0,
      groupPage: 1,
      groupPageSize: 10,
      groupTotal1: 0,
      groupPage1: 1,
      groupPageSize1: 10,
      currentPage: 1, //分页
      moduleList: [],
      activeKey: "1",
      uid: "",
      interfaceList: [],
      billingType: [
        { value: 0, name: "月租" },
        { value: 1, name: "单计费" },
        { value: 2, name: "双计费" },
      ],
      privacylist: [
        {
          uid: "", //用户id
          interfaceId: "", //接口类型
          type: null, //价格类型
          price: "", //价格
          status: 0, //模块状态
        },
      ],
      doubleClick: false,
      otherList: {
        uid: "", //用户id
        usePrice: "", //用户使用价格
        apiPrice: "", //接口能力价格
        useStatus: 0, //开通状态
        apiStatus: 0, //能力状态
        asrPrice: "", //一句话价格
        asrStatus: 0, //一句话状态
      },
    };
  },
  computed: {},
  watch: {
    activeKey(news, old) {
      this.otherList.uid = localStorage.getItem("uid");
      let uid = localStorage.getItem("uid");
      if (news == "1") {
        this.getUserPriceQuery(uid);
      }
      if (news == "2") {
        this.getByUidVoice(uid);
      }
      if (news == "3") {
        this.getByUidBlack(uid);
      }
    },
  },
  mounted() {
    this.getApiType();
    this.getDataList();
    this.getInterfaceQuery();
    this.getDownLowData();
  },
  methods: {
    // 分页
    handleSizeChange(val) {
      this.groupPageSize1 = val
      this.viewNotes()
    },
    handleCurrentChange(val) {
      this.groupPage1 = val
      this.currentPage = val
     this.viewNotes()
    },
    //查看备注
    viewNotes(){
      let uid=localStorage.getItem("uid");
      webUser.userPricesRemarkList({
        uid:uid,
        page: this.groupPage1,
        pageSize: this.groupPageSize1,
      }).then(res=>{
        if (res.code==200) {
          this.tableData=res.data.records
            this.dialogVirtualVisible1=true
            this.groupTotal1=res.data.total
            console.log(res);
        }
        
      })
       
    },
    //添加备注按钮
    addNotes(pItem){
      this.userData=pItem
      this.textarea1=''
      this.dialogVirtualVisible=true
    },
    handleVirtualAdd(){
      this.loading=true
         webUser.userPricesRemarkAdd({
        uid: this.userData.uid,
        interfaceId:this.userData.interfaceId,
        remark:this.textarea1
      }).then(res=>{
        if (res.code==200) {
              this.$notify({
                title: "成功",
                message: "添加备注成功！",
                type: "success",
              });
               this.loading=false
                this.dialogVirtualVisible=false
        }else{
          this.$notify({
                title: "失败",
                message:res.message||res.msg,
                type: "error",
              });
        }
      })
    },  
    // 获取下拉数据
    getDownLowData() {
      // 获取通信账户类型
      amountts.getAmountList({}).then((res) => {
        this.amount_id = res.data;
      });
    },
    queryprivacy() {
      this.groupPage = 1;
      let uid = localStorage.getItem("uid");
      this.getUserPriceQuery(uid);
    },
    getApiType() {
      interfacets
        .getOption({
          uid: localStorage.getItem("uid"),
          optionKey: "black_resource",
        })
        .then((res) => {
          this.telTypeRadio = res.data.optionValue;
        });

      interfacets.seeApiList({}).then((res) => {
        this.interface_id = res.data;
      });
    },
    handleGroupChange(params) {
      this.groupPageSize = params.limit;
      this.groupPage = params.page;

      let uid = localStorage.getItem("uid");
      this.getUserPriceQuery(uid);
    },

    // 获取用户id
    getUid(id) {
      if (id == undefined || id == null) {
        this.uid = localStorage.getItem("uid");
      } else {
        this.uid = id;
      }
      this.getUserPriceQuery(id);
    },
    //    //全部初始化
    //    init(){

    //    },
    // 获取隐私号用户价格
    getInterfaceQuery() {
      interfaces.interfaceQueryName({}).then((res) => {
        this.interfaceList = res.data;
      });
    },
   
    // 获取模块列表
    getDataList() {
      app.appList({}).then((res) => {
        let newModuleList = res.data.records;
        newModuleList.forEach((el, e) => {
          //去除系统模块
          if (el.id == 0) {
            newModuleList.splice(e, 1);
          }
        });
        this.moduleList = newModuleList;
      });
    },
    // 获取隐私号用户价格
    getUserPriceQuery(id) {
      userPrice
        .userPriceQueryplus({
          uid: id,
          page: this.groupPage,
          pageSize: this.groupPageSize,
          interfaceId: this.interfaceIdType,
          ...this.searchForm,
        })
        .then((res) => {
          if (res.data.length != 0) {
            this.privacylist = res.data.records;
            this.groupTotal = res.data.total;
          } else {
            this.privacylist = [
              {
                uid: localStorage.getItem("uid"),
                interfaceId: "",
                type: null,
                price: "",
                status: 0,
              },
            ];
          }
        });
    },
    // ===============隐私号
    // 添加一条
    addprivacy() {
      let newJson = {
        uid: this.uid, //用户id
        interfaceId: "", //接口类型
        type: null, //价格类型
        price: "", //价格
        status: 0, //模块状态
      };
      // this.newJson.uid = this.uid
      this.privacylist.push(newJson);
    },
    // 删除
    deleteOne(index, item) {
      this.$confirm("将删除该条记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (item.interfaceId == '') {
            this.privacylist.splice(index, 1);
          } else {
            userPrice
              .userPriceDeleteplus({
                uid: item.uid,
                interfaceId: item.interfaceId,  
              })
              .then((res) => {
                this.privacylist.splice(index, 1);
              });
          }
        })
        .catch(() => {
          // this.$notify.info({
          //     title: '通知',
          //     message: '已取消删除!'
          // });
        });
    },
    // 取消
    cancel() {
      this.getUserPriceQuery(this.uid);
    },
    transformItem(item) {
 
  
  // 创建三个新的项目
  var itemMonthlyRent = {
    interfaceId: item.interfaceId,
    type: 0,
    price: item.monthlyRent,
    status: item.status,
    uid:item. uid
  };

  var itemSingleBilling = {

    interfaceId: item.interfaceId,
    type: 1,
    price: item.singleBilling,
    status: item.status,
    uid:item. uid,
   
  };

  var itemDualBilling = {

    interfaceId: item.interfaceId,
    type: 2,
    price: item.dualBilling,
    status: item.status,
    uid:item. uid,

 
  };

  return [itemMonthlyRent, itemSingleBilling, itemDualBilling];
},
 changeSel(pItem){
       interfaces.getInterfacePrice({
        id:pItem.interfaceId
       }).then(res=>{
          pItem.monthlyRent1 = res.data[0];
          pItem.singleBilling1 = res.data[1];
          pItem.dualBilling1 = res.data[2];
       })
    },
    showPrice(pItem){
       interfaces.getInterfacePrice({
        id:pItem.interfaceId
       }).then(res=>{
          pItem.monthlyRent1 = res.data[0];
          pItem.singleBilling1 = res.data[1];
          pItem.dualBilling1 = res.data[2];
       })
    },
 validateItem(pItem) {
   if (pItem.monthlyRent1 === -1) {
    return true;
  }
 
      return !(pItem.monthlyRent1 === -1 || pItem.monthlyRent1 > pItem.monthlyRent);
    },
     validateItem1(pItem) {
        if (pItem.singleBilling1 === -1) {
    return true; 
  }
      return !(pItem.singleBilling1 === -1 || pItem.singleBilling1 > pItem.singleBilling);
    },
     validateItem2(pItem) {
   if (pItem.dualBilling1 === -1) {
    return true;
  }
      return !(pItem.dualBilling1 === -1 || pItem.dualBilling1 > pItem.dualBilling);
    },
    // 保存
    save() {
       let hasInvalidCondition = false;
       let invalidMessages = [];
      for (const pItem of this.privacylist) {
        if (pItem.monthlyRent1>0) {
             if (pItem.monthlyRent==null||pItem.monthlyRent=='') {
                  return this.$notify.info({
          title: "通知",
          message: "请完善月租价格!",
        });
             }
        }
        if (pItem.singleBilling1>0) {
             if (pItem.singleBilling==null||pItem.singleBilling=='') {
                  return this.$notify.info({
          title: "通知",
          message: "请完善单计费价格!",
        });
             }
        }
        if (pItem.dualBilling1>0) {
             if (pItem.dualBilling==null||pItem.dualBilling=='') {
                  return this.$notify.info({
          title: "通知",
          message: "请完善双计费价格!",
        });
             }
        }
          if (!this.validateItem(pItem)||!this.validateItem1(pItem)||!this.validateItem2(pItem)) {
 invalidMessages.push(pItem.interfaceId);
          console.log('校验不通过');
       if (!hasInvalidCondition) {
        hasInvalidCondition = true;
      }
        }
        //  if (!this.validateItem1(pItem)) {
        //   console.log('校验不通过，不能保存2');
        //   return   this.$notify.info({
        //   title: "通知",
        //   message: "低于成本价，无法保存!",
        // });
        // }
        //  if (!this.validateItem2(pItem)) {
        //   console.log('校验不通过，不能保存3');
        //   return this.$notify.info({
        //   title: "通知",
        //   message: "低于成本价，无法保存!",
        // });
        // }
      }

      if (invalidMessages.length > 0) {
 let matchedNames = this.interfaceList
  .filter(item => invalidMessages.includes(item.id))
  .map(item => item.name);
    this.$notify.info({
      title: "通知",
      message: ` ${matchedNames.join('、')}线路的价格低于对外价格`,
    });
  }
      // 对数组中的每个项执行转换
let outputArray = this.privacylist.flatMap(this.transformItem);

      if (this.privacylist.length == 0) {
        this.$notify.info({
          title: "通知",
          message: "请设置配置项后再保存!",
        });
      } else {
        if (this.doubleClick == false) {
          this.doubleClick = true;
          // this.privacylist[0].uid = this.uid
          userPrice
            .userPriceAdd({
              appId: this.activeKey,
              uid: this.uid,
              userPriceDtoList: outputArray,
            })
            .then((res) => {
              let uid = localStorage.getItem("uid");
              this.getUserPriceQuery(uid);
              this.$notify({
                title: "成功",
                message: "隐私号服务商设置完成！",
                type: "success",
              });
              this.doubleClick = false;
            })
            .catch((err) => {
              this.doubleClick = false;
            });
        }
      }
    },
    // ===========黑名单 or 语音质检
    getByUidBlack(id) {
      blackUserPrice
        .getByUid({
          uid: id,
        })
        .then((res) => {
          if (res.data != undefined) {
            this.otherList = res.data;
          } else {
            this.otherList = {
              uid: localStorage.getItem("uid"),
              usePrice: "",
              apiPrice: "",
              useStatus: 0,
              apiStatus: 0,
            };
          }
        });
    },
    getByUidVoice(id) {
      voiceUserPrices
        .getByUid({
          uid: id,
        })
        .then((res) => {
          if (res.data != undefined) {
            this.otherList = res.data;
          } else {
            this.otherList = {
              uid: localStorage.getItem("uid"),
              usePrice: "",
              apiPrice: "",
              useStatus: 0,
              apiStatus: 0,
            };
          }
        });
    },
    // 取消
    cancelOther(id) {
      let uid = localStorage.getItem("uid");
      if (this.activeKey == "2") {
        getByUidVoice(uid);
      }
      if (this.activeKey == "3") {
        getByUidBlack(uid);
      }
    },
    // 保存
    saveOther() {
      userModule
        .addUserModule({
          appId: this.activeKey,
          uid: this.uid,
          [this.activeKey == "2" ? "voiceUserPriceDto" : "blackUserPriceDto"]:
            this.otherList,
        })
        .then((res) => {
          if (res.code !== 200) {
            message.error(res.message);
            return false;
          }

          if (this.activeKey == 3) {
            interfacets
              .setOption({
                uid: localStorage.getItem("uid"),
                optionKey: "black_resource",
                optionValue: this.telTypeRadio,
              })
              .then((res) => {});
          }

          this.$notify({
            title: "成功",
            message:
              this.activeKey == "2" ? "语音质检设置完成！" : "黑名单设置完成！",
            type: "success",
          });
          this.$emit("closeDialog", close);
        });
    },
  },
};
</script>

<style scoped>
/* .out-left{
        display: inline-block;
        margin-left: 1rem;
    } */
.input-box {
  display: inline-block;
  width: 206px;
}
.input-box-other {
  width: 15rem;
}
.add-box {
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
}
.bottom-box {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}
.bottom-box1 {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.pItem-box {
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.privacy-most {
  height: calc(100vh - 500px);
  overflow-y: overlay;
}
.input-far-width {
  width: 390px;
}
.input-name-price-width {
  display: inline-block;
  width: 180px;
}
 .input-price-width {
  width: 200px;
    
}
 .input-error {
  border: 2px solid red;
  border-radius: 10px;
  width: 206px;
  padding-left: 1px;
  border-color: red;
}
.testShow{
  color: red;
}
.flex1{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.confirmAdd {
  background: #637DFF;
  color: #fff;
}
.confirmAdd:hover{
    background: #637DFF;
  color: #fff;
}
.cancel {
  color: #637DFF;
  border: #637DFF solid 1px;
}
.cancel:hover {
  color: #637DFF;
  border: #637DFF solid 1px;
}
.pager {
  display: flex;
  justify-content: flex-end;
}
</style>
